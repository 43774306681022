import React from "react"
import Layout from "../../components/layout"
import Screenshot from "../../img/client-genesis-web.jpg"

export default ({ location, data }) => {
  return (
    <Layout location={location}>
      <div className="cover-heading-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-sm-10">
              <h1 className="cover-heading">Genesis Media</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="cover-wrapper cover-genesis">
        <div className="cover-wrapper-inner">
          <div className="cover-container">
            <div className="inner cover"></div>
          </div>
        </div>
      </div>

      <section className="m-t-100 sm-m-t-20">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="">
                <h4>Client</h4>
                <p className="m-b-20">Genesis Media, NY</p>

                <h4>Roles</h4>
                <p className="m-b-20">Web Design/Development</p>

                <p className="m-b-20">
                  <a rel="noopener" href="http://genesismedia.com" target="_blank">
                    genesismedia.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-7">
              <p>
                We gave a brand new look to the official site of Genesis Media.
                We condensed all the info provided to a single-page, responsive
                site.
              </p>
            </div>
          </div>

          <div className="shot m-t-50 sm-m-t-20 m-b-50 sm-m-b-20">
            <img alt="Genesis Media logo" src={Screenshot} />
          </div>
        </div>
      </section>
    </Layout>
  )
}
